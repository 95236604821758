<template>
    <div class="red-signet-main">
        <div class="title">
            <p>חותמות אדומות</p>
        </div>
        <div class="main-menu-option">
            <Button
                label="טופס חדש"
                style="width:260px; height:100px; margin:5px; font-size: 2rem;"
                class="p-button-success"
                @click="handle_show_new_form"
            />
            <Button
                v-if="user_role>=99"
                label="ניהול חותמות אדומות"
                style="width:260px; height:100px; margin:5px; font-size: 2rem;"
                class="p-button-warning"
                @click="show_manage_forms=!show_manage_forms"
            />
        </div>
    </div>

    <Dialog
      header="טופס חותמות אדומות"
      v-model:visible="show_new_form"
      :modal="true"
      position="center"
    >
      <NewForm @close="show_new_form=false" />
    </Dialog>
    <Dialog
      header="ניהול חותמות אדומות"
      v-model:visible="show_manage_forms"
      :modal="true"
      position="center"
    >
      <ManageForms />
    </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import NewForm from './components/NewForm.vue'
import ManageForms from './components/ManageForms.vue'
import { ref } from '@vue/reactivity';
import store from '../../../store';
import Swal from 'sweetalert2'
import {alert} from '../../../Methods/Msgs'
import { computed } from '@vue/runtime-core';
import { projectFirestore } from '../../../firebase/config';
export default {
    components:{Dialog,NewForm,ManageForms},
    setup(){
        const user_role = ref(computed(()=>{
            return store.getters.role
        }))

        const handle_show_new_form = async()=>{
            const passed =localStorage.getItem('red_signet')
            if(passed && passed=='passed'){
                show_new_form.value = true
            }else{
                const { value: password } = await Swal.fire({
                    title: 'נדרש סיסמה לכניסה',
                    input: 'password',
                    inputLabel: 'חותמות אדומות',
                    inputPlaceholder: 'הזן/ני סיסמה',
                    inputAttributes: {
                        maxlength: 10,
                        autocapitalize: 'off',
                        autocorrect: 'off'
                    }
                })
                if (password) {
                    const doc = await projectFirestore.collection('Applications').doc('ElRmAMsdxLy15tL4oFiF').get()
                    const pass = doc.data().password
                    if(pass == password){
                        localStorage.setItem('red_signet','passed')
                        show_new_form.value = true
                    }else{
                        alert('error','שגיאה','הסיסמה לא נכונה!')
                    }
                }
            }
        }
        const show_manage_forms = ref(false)
        const show_new_form = ref(false)

        return{
            handle_show_new_form,
            show_new_form,
            show_manage_forms,
            user_role,
        }
    }
}
</script>

<style scoped>
    .red-signet-main{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 25px;
    }
    .main-menu-option{
        width: 350px;
        height: 80vh;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
</style>
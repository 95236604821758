<template>
  <div v-if="desktop" class="manage-forms">
      <div class="header">
        <div class="search">
            <span class="p-input-icon-left" style="width:100%">
            <i class="pi pi-search" />
            <InputText
                type="text"
                v-model="search"
                placeholder="חיפוש"
                style="width:100%"
            />
            </span>
        </div>
        <div class="display-options">
        <Dropdown 
          style="width:100%;"
          v-model="selected_year"
          :options="display_years"
          placeholder="בחירת שנה"
        />
        </div>
        <div class="display-options">
        <MultiSelect 
          style="width:100%;"
          v-model="selected_months"
          :options="display_months"
          placeholder="בחירת חודשים"
        />
        </div>
        <div class="date-from">
            <input type="date" v-model="fromDate" />
        </div>
        <div class="date-to">
            <input type="date" v-model="toDate" />
        </div>
        <div class="clear-dates">
            <Button
            label="איפוס תאריכים"
            class="p-button-secondary"
            @click="handleClearDates"
            />
        </div>
        <div class="export-to-excel">
            <Button
            icon="pi pi-file-excel"
            class="p-button-rounded p-button-success"
            @click="handleExportToCsv"
            />
        </div>
      </div>
      <div class="dashboard">
        <div class="frame total">
            <p>סה"כ רשומות</p>
            <p>{{counter_value}}</p>
        </div>
        <div class="frame current">
            <p>מספר רשומות במסך נוכחי</p>
            <p>{{sorted_records.length}}</p>
        </div>
      </div>
      <div class="display-btns">
        <Button
            style="margin-left:50px"
            label="הצג לפי סינון"
            class="p-button-info"
            @click="handle_display_data"
        />
        <Button
            style="margin-left:50px"
            label="הצג הכל"
            class="p-button-secondary"
            @click="handle_display_all"
        />
      </div>
      <div class="content">
          <table id="records">
              <tr>
                  <th @click="handleSortTable('נוצר בתאריך', !selectedSorted.order)">נוצר בתאריך</th>
                  <th @click="handleSortTable('תאריך', !selectedSorted.order)">תאריך חותמת</th>
                  <th @click="handleSortTable('שם הספק', !selectedSorted.order)">שם הספק</th>
                  <th @click="handleSortTable('שם הסניף', !selectedSorted.order)">שם הסניף</th>
                  <th @click="handleSortTable('שם המזין', !selectedSorted.order)">שם המזין</th>
                  <th><Checkbox v-model="checkeAll" :binary="true" /></th>
              </tr>
               <h1 style="text-align:center;" v-if="sorted_records.length==0 && !isPending">אין רשומות</h1>
               <template v-for="record in sorted_records" :key="record.id">
                   <tr>
                       <td>{{record.createdAt.toDate().toLocaleDateString('he')}}</td>
                       <td>{{record.selected_date.toDate().toLocaleDateString('he')}}</td>
                       <td>{{record.selected_supplier}}</td>
                       <td>{{record.selected_branche}}</td>
                       <td>{{record.user_name}}</td>
                       <td><Checkbox name="record" :value="record.id" v-model="check_records"/></td>
                   </tr>
               </template> 
          </table>
      </div>
  </div>
    <Loader v-if="isPending"/>
    <Dialog
            header="חלון מחיקה"
            v-model:visible="showDeleteDialog"
            :style="{ width: '30vw' }"
            position="bottom"
        >
            <p class="p-m-0">האם את/ה בטוח שברצונך למחוק את הרשומות המסומנות?</p>
            <template #footer>
            <Button
                label="לא"
                @click="handleDeleteChecks('לא')"
                class="p-button-success"
            />
            <Button
                label="כן"
                @click="handleDeleteChecks('כן')"
                class="p-button-danger"
            />
            </template>
    </Dialog>

     <OnlyForDesktop v-if="!desktop" />
</template>

<script>
import InputText from "primevue/inputtext";
import MultiSelect from 'primevue/multiselect';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import Dialog from 'primevue/dialog';
import Loader from '../../officeRequests/components/manage_side/loader/Loader.vue'
import OnlyForDesktop from '../../../Not_responsible/OnlyForDesktop.vue'
import XLSX from "xlsx";
import {projectFirestore,firebase} from '../../../../firebase/config'
import {alert} from '../../../../Methods/Msgs'
import { ref } from '@vue/reactivity';
import { computed, onMounted, onUnmounted, watch } from '@vue/runtime-core';
export default {
    components:{InputText,MultiSelect,Dropdown,Checkbox,Dialog,Loader,OnlyForDesktop},
    setup(){
        const desktop = ref(true)
        const error_msg = ref('')
        const records = ref([])
        const selected_year = ref('')
        const selected_months = ref([])
        const display_years = ref([
            '2019',
            '2020',
            '2021',
            '2022',
            '2023',
            '2024',
            '2025'
        ])
        const display_months = ref([
            'ינואר',
            'פברואר',
            'מרץ',
            'אפריל',
            'מאי',
            'יוני',
            'יולי',
            'אוגוסט',
            'ספטמבר',
            'אוקטובר',
            'נובמבר',
            'דצמבר'
        ])
        const check_records = ref([])
        const checkeAll = ref(false)
        const showDeleteDialog = ref(false)
        const isPending  = ref(false)
        const fromDate = ref('')
        const toDate = ref('')
        const counter_value = ref(0)

        const search = ref('')

        const sorted_records = ref(computed(() => {
            if (selectedSorted.value.name == "")return filter_records_by_search.value;
            if (selectedSorted.value.name == "נוצר בתאריך") {
                return filter_records_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.createdAt.toDate() >  b.createdAt.toDate() 
                        ? 1
                        : b.createdAt.toDate()  > a.createdAt.toDate() 
                        ? -1
                        : 0;
                    }
                    return a.createdAt.toDate() > b.createdAt.toDate() 
                    ? -1
                    : b.createdAt.toDate()  > a.createdAt.toDate() 
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "תאריך") {
                return filter_records_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.selected_date.toDate() >  b.selected_date.toDate() 
                        ? 1
                        : b.selected_date.toDate()  > a.selected_date.toDate() 
                        ? -1
                        : 0;
                    }
                    return a.selected_date.toDate() > b.selected_date.toDate() 
                    ? -1
                    : b.selected_date.toDate()  > a.selected_date.toDate() 
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "שם הספק") {
                return filter_records_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.selected_supplier >  b.selected_supplier
                        ? 1
                        : b.selected_supplier  > a.selected_supplier
                        ? -1
                        : 0;
                    }
                    return a.selected_supplier > b.selected_supplier
                    ? -1
                    : b.selected_supplier > a.selected_supplier 
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "שם הסניף") {
                return filter_records_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.selected_branche >  b.selected_branche
                        ? 1
                        : b.selected_branche  > a.selected_branche
                        ? -1
                        : 0;
                    }
                    return a.selected_branche > b.selected_branche
                    ? -1
                    : b.selected_branche > a.selected_branche 
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "שם המזין") {
                return filter_records_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.user_name >  b.user_name
                        ? 1
                        : b.user_name  > a.user_name
                        ? -1
                        : 0;
                    }
                    return a.user_name > b.user_name
                    ? -1
                    : b.user_name > a.user_name 
                    ? 1
                    : 0;
                });
            }
            
            
            
            
            
            
        }));
        
        const handleSortTable = (name, order) => {
            selectedSorted.value.name = name;
            selectedSorted.value.order = order;
        };
        const selectedSorted = ref({
            name: "",
            order: false
        });
        
        const filter_records_by_search = ref(computed(()=>{
            if(search.value==""){
                if(fromDate.value && toDate.value){
                        return records.value.filter(record=>{
                            if(stringTodate(fromDate.value)<=timestampToDate(record.selected_date) &&
                            stringTodate(toDate.value)>=timestampToDate(record.selected_date) ){
                                return record
                            }
                        })
                }
                return records.value;    
            }
            else{
                    let results=records.value.filter(record => {
                        if (new Date(record.selected_date.seconds * 1000).toLocaleDateString('he').includes(search.value)) return record;
                        if (record.selected_branche.includes(search.value)) return record;
                        if (record.selected_supplier.includes(search.value)) return record;
                        if (record.user_name.includes(search.value)) return record;
                    });
                    if(fromDate.value && toDate.value){
                        return results.filter(record=>{
                            if(stringTodate(fromDate.value)<=timestampToDate(record.selected_date) &&
                            stringTodate(toDate.value)>=timestampToDate(record.selected_date) ){
                                return record
                            }
                        })
                    }else{
                        return results
                    }
            }
        }))
        
        const handleClearDates=()=>{
            fromDate.value=''
            toDate.value=''
        }

        const handle_display_data=async()=>{
            if(!validation()){
                alert('error','שגיאה',error_msg.value)
            }else{
                await handle_records()
            }
        }
        const handle_display_all = async()=>{
            await all_records_from_db()
        }

        const validation = ()=>{
            if(!selected_year.value || selected_months.value.length == 0){
                error_msg.value = 'עליך לבחור שנה וחודש/ים'
                return false
            }
            if(selected_months.value.length>10){
                 error_msg.value = 'עליך לסמן עד 10 חודשים בלבד'
                 return false
            }
            return true
        }
        const handle_records = async()=>{
            isPending.value = true
            const docs = await projectFirestore.collection('Applications').doc('ElRmAMsdxLy15tL4oFiF')
            .collection('Forms').where('year','==',Number(selected_year.value)).where('month','in',selected_months.value).orderBy('createdAt','desc').get()
            records.value = docs.docs.map(doc=>doc.data())
            isPending.value = false
        }

        const all_records_from_db=async()=>{
            isPending.value = true
            const docs = await projectFirestore.collection('Applications').doc('ElRmAMsdxLy15tL4oFiF')
            .collection('Forms').orderBy('createdAt','desc').get()
            records.value = docs.docs.map(doc=>doc.data())
            isPending.value = false
        }

        const handleDeleteChecks=async(option)=>{
            if(option=='לא'){
                showDeleteDialog.value=false
                check_records.value=[]
                checkeAll.value=false
            }
            if(option=='כן'){
                isPending.value = true
                for (const recordId of check_records.value){
                   
                    await delete_record(recordId)

                    const index = records.value.findIndex(_record=>_record.id == recordId)
                    if(index!=-1){
                      await decrement()
                      records.value.splice(index,1)
                    }
                }
                showDeleteDialog.value=false
                check_records.value=[]
                isPending.value=false
                checkeAll.value=false
            }
        }

        const delete_record = async(recordId)=>{
            await projectFirestore.collection('Applications').doc('ElRmAMsdxLy15tL4oFiF')
            .collection('Forms').doc(recordId).delete()
        }

        const decrement = async()=>{
            await projectFirestore.collection('Applications').doc('ElRmAMsdxLy15tL4oFiF')
            .update({
                forms_number:firebase.firestore.FieldValue.increment(-1),
            })
        }
        // for dates
        const stringTodate=(dateString)=>{
            const d=new Date(dateString)
            d.setHours(0,0,0,0)
            return d
        }
        const timestampToDate=(date)=>{
            const d = new Date(date.seconds * 1000)
            d.setHours(0,0,0,0)
            return d
        }

        const initial = async()=>{
            if(localStorage.getItem('Signet_search')){
                    search.value = localStorage.getItem('Signet_search')
            }
            if(localStorage.getItem('Signet_months')){
                    selected_months.value = localStorage.getItem('Signet_months').split(",")
            }
            if(localStorage.getItem('Signet_year')){
                    selected_year.value = localStorage.getItem('Signet_year')
            }
            if(selected_months.value.length>0 && selected_months.value.length<=10){
                if(selected_year.value){
                    await handle_records()
                }
            }

        }

        const handleExportToCsv=()=>{
            let rows = JSON.parse(JSON.stringify(sorted_records.value)) 
            rows.forEach(row=>{
                row['תאריך']=new Date(row.selected_date.seconds * 1000).toLocaleDateString('he').split('.').join('/')
                row['שנה'] = row.year
                row['חודש'] = row.month
                row['שם ספק']=row.selected_supplier
                row['שם הסניף']=row.selected_branche
                row['שם המזין']=row.user_name
                delete row.selected_date
                delete row.selected_supplier
                delete row.selected_branche
                delete row.user_name
                delete row.year
                delete row.id
                delete row.createdAt
                delete row.month
            })
            let workSheet = XLSX.utils.json_to_sheet(rows);
            let workbook = XLSX.utils.book_new();
            workbook.Workbook = {};
            workbook.Workbook.Views = [];
            workbook.Workbook.Views[0] = {};
            workbook.Workbook.Views[0].RTL = true;

            XLSX.utils.book_append_sheet(workbook, workSheet, "רשימת חותמות אדומות ");
            XLSX.writeFile(workbook, "חותמות-אדומות.xlsx");
        }
        watch(search,()=>{
            localStorage.setItem("Signet_search",search.value);
        })
        watch(selected_months,()=>{
            localStorage.setItem("Signet_months",selected_months.value);
        })
        watch(selected_year,()=>{
            localStorage.setItem("Signet_year",selected_year.value);
        })
        watch(checkeAll,()=>{
            if(checkeAll.value){
                check_records.value=sorted_records.value.map(record=>record.id)
            }
            else{
                check_records.value=[]
            }
        })
        watch(check_records,()=>{
            if(check_records.value.length>0){
                showDeleteDialog.value=true
            }
            else {
                showDeleteDialog.value=false
            }
        })
        
       let counter_unsub
       const counter_records_RT=()=>{
           counter_unsub = projectFirestore.collection('Applications').doc('ElRmAMsdxLy15tL4oFiF')
           .onSnapshot(doc=>{
               counter_value.value = doc.data().forms_number
           })
       }

       window.addEventListener('resize', ()=>{
            if(window.innerWidth<600){
                desktop.value = false
            }else{
                desktop.value = true
            }
        });
        onMounted(async()=>{
            if(window.innerWidth<600){
                desktop.value = false
            }else{
                desktop.value = true
            }
            await initial()
            counter_records_RT()
        })

        onUnmounted(()=>{
            window.removeEventListener('resize', ()=>{
                if(window.innerWidth<600){
                    desktop.value = false
                }else{
                    desktop.value = true
                }
            });
            if(counter_unsub){
                counter_unsub()
            }
        })
        return{
            handleClearDates,
            handle_display_data,
            handle_display_all,
            handleSortTable,
            handleDeleteChecks,
            handleExportToCsv,
            sorted_records,
            filter_records_by_search,
            search,display_years,
            selected_year,
            display_months,
            selected_months,
            fromDate,
            toDate,
            selectedSorted,
            records,
            check_records,
            checkeAll,
            showDeleteDialog,
            isPending,
            counter_value,
            desktop
        }
    }
}
</script>

<style scoped>
    .manage-forms{
        width: 80vw;
        height: 80vh;
    }
    .header{
        padding: 1.5rem;
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: space-between;
        align-items: center; 
    }
    .display-options{
        width:15% ;
    }
    input[type="date"] {
        height: 35px;
        border: 0.5px solid lightgrey;
        border-radius: 3px;
    }
    input[type="date"]:hover {
        border: 1.1px solid lightblue;
    }
    input[type="date"]:focus {
        outline: none;
        border: 1.1px solid lightblue;
    }
    .dashboard{
        width: 100%;
        height: 5%;
        display: flex;
        justify-content: center;
        padding: 0 5px;
    }
    .dashboard .frame{
        width: 19%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-radius: 10px;
        margin-left: 5px;
    }
    .dashboard .total{
        background-color: teal;
    }
    .dashboard .current{
        background-color: grey;
    }
    .display-btns{
        margin: 5px;
        width: 100%;
        height: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .content{
        position: relative;
        width: 100%;
        height: 80%;
        overflow: hidden;
        overflow-y: auto;
    }
    #records {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    #records td, #records th {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: center;
    }

    #records tr:nth-child(even){
        background-color: #f2f2f2;
    }

    #records tr:hover {
        background-color: #ddd;
    }

    #records th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: center;
        background-color: #04AA6D;
        color: white;
        cursor: pointer;
    }
    
</style>